import React from "react";

export default function Header() {
  return (
    <>
      <header role={`heading`} className="header">
        <h4 className="header__hi">hey &#128075;&#127998;, i'm</h4>
        <h1 className="header__name">
          <span className="header__namee">ayo aluko</span>
        </h1>
      </header>
    </>
  );
}
