import * as React from "react";
import "../components/styles.scss";
import Cursor from "../components/Cursor";
import Footer from "../components/footer";
import Header from "../components/header";
import Main from "../components/main";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <>
    <div className="app_cover">
      <SEO />
      <Cursor />
      <Header />
      <Main className= "main1" />
      <Footer />
    </div>
    </>
  );
};

export default IndexPage;
